@import "~@ui/styles/tools";
@import "src/styles/variables.scss";

@mixin medium-breakpoint {
    @media screen and (max-width: 1350px) {
        @content;
    }
}

.container {
    background: white;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.185s ease-in-out;
    width: 49px;
    height: 49px;

    &:hover {
        color: $color-title-lighter !important;
        background-color: $color-page-background;
    }

    &:active {
        background-color: $color-light-gray;
    }
}

.icon {
    position: relative;
    width: 24px;
    height: 24px;
}